import React from 'react';

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="28"
			viewBox="0 0 24 28"
			className="mr-2 mb-1"
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<path
					fill="#2C1B6F"
					d="M10.476.234s2.027.49 4.118 1.814c2.156 1.305 4.479 3.378 6.154 5.858 1.684 2.48 2.766 5.34 3.09 7.8.38 2.425-.027 4.452-.027 4.452-1 4.978-5.84 8.208-10.827 7.217-.102-.027-.26-.064-.37-.092 0 0-2.008-.49-4.044-1.86-2.092-1.333-4.23-3.526-5.756-6.098C1.278 16.743.38 13.763.112 11.265c-.305-2.462.11-4.507.11-4.507C1.14 2.196 5.6-.756 10.172.17c.092.018.212.046.305.064"
					transform="translate(-787 -1795) translate(-64 1521) translate(851 172) translate(0 102)"
				/>
				<path
					fill="#50E3C2"
					d="M10.476.234s2.027.49 4.118 1.814c2.156 1.305 4.479 3.378 6.154 5.858 1.684 2.48 2.766 5.34 3.09 7.8.38 2.425-.027 4.452-.027 4.452-1 4.978-5.84 8.208-10.827 7.217-.102-.027-.26-.064-.37-.092 0 0-2.008-.49-4.044-1.86-2.092-1.333-4.23-3.526-5.756-6.098C1.278 16.743.38 13.763.112 11.265c-.305-2.462.11-4.507.11-4.507C1.14 2.196 5.6-.756 10.172.17c.092.018.212.046.305.064"
					transform="translate(-787 -1795) translate(-64 1521) translate(851 172) translate(0 102) matrix(-1 0 0 1 24 0)"
				/>
			</g>
		</svg>
	);
}

export default Icon;
