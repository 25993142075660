import React from 'react';
import Img from 'gatsby-image';
import './CourierRequirements.scss';

const CourierRequirements = ({ images }) => {
	const { illu_vehicle, illu_phone, illu_contract, illu_deliver } = images;

	const content = [
		{
			title: 'Saját szállítási eszköz',
			text: 'Saját biciklivel, motorral, kocsival vagy akár gyalog is futárkodhatsz. Táskát és öltözetet biztosítunk!',
			img: illu_vehicle,
		},
		{
			title: 'Okostelefon',
			text: 'Okostelefonod segítségével tudod nyomon követni a fuvarjaidat. Alkalmazásunk fut Android és iOS készülékeken is.',
			img: illu_phone,
		},
		{
			title: 'Szerződéses jogviszony',
			text: 'Tudunk szerződni egyéni vállalkozásoddal, illetve ha hallgatói jogviszonyod van. De kipróbálhatod a melót akkor is, ha egyik sincsen. Segítünk, hogy belevághass!',
			img: illu_contract,
		},
		{
			title: 'Pozitív hozzáállás',
			text: 'Ügyfeleink azért szeretnek minket, mert kiemelkedő szállítási élményt nyújtunk, amihez a Te hozzáállásodra is szükség van!',
			img: illu_deliver,
		},
	];

	return (
		<section id="courier-requirements" className="bg-primary">
			<div className="container">
				<div className="row pb-sm-5 mb-sm-4">
					<div className="col-12">
						<h2 className="text-center my-5 pt-4">
							Amire szükséged van, hogy ViddL futár lehess
						</h2>
					</div>
					{content.map(({ title, text, img }) => (
						<div className="col-12 col-md-6" key={title}>
							<div className="row">
								<div className="col-12 px-0 col-lg-6">
									<Img
										fluid={img.childImageSharp.fluid}
										style={{
											maxWidth: 180,
											width: '70%',
											marginLeft: 'auto',
											marginRight: 'auto',
										}}
										alt={title}
										className="mt-5 mb-3 mr-lg-3"
									/>
								</div>
								<div className="col-12 px-0 col-lg-6">
									<h4 className="text-center text-lg-left my-4">{title}</h4>
									<p className="text-center text-lg-left">{text}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default CourierRequirements;
