import React from 'react';
import Img from 'gatsby-image';
import ListBullet from '../common/ListBullet';
import './USP.scss';

const texts = [
	{
		title: 'TE VAGY A FŐNÖK',
		description: 'Saját főnököd lehetsz, nem dirigál senki',
	},
	{
		title: 'TE OSZTOD BE A MUNKÁT',
		description:
			'Saját beosztásod szerint dolgozhatsz, az életedhez alakíthatod a munkádat',
	},
	{
		title: 'DOLGOZZ JOBBAN, KERESS TÖBBET',
		description:
			'Teljesítményed alapján keresel, ha jól dolgozol akár 2.500 Forintot is kereshetsz óránként',
	},
	{
		title: 'KIVÁLASZTHATOD SZÁLLÍTÁSI ESZKÖZÖDET',
		description:
			'Megválaszthatod, hogy mivel szállítasz. Lehetsz biciklis, motoros, autós vagy akár gyalogos futár is!',
	},
	{
		title: 'SEGÍTJÜK MUNKÁDAT',
		description: 'Menő applikációt adunk a kezedbe, ami segíti a munkádat',
	},
];

export const USP = ({ images }) => {
	const { app } = images;
	return (
		<section id="usp">
			<div className="container">
				<div className="row">
					<div className="col-md-6" id="app">
						<Img
							fluid={app.childImageSharp.fluid}
							alt="5 ok, amiért megéri ViddL futárnak lenni"
							style={{
								maxWidth: 500,
							}}
						/>
					</div>
					<div className="col-12 col-md-6">
						<h2 className="text-center mb-5 text-md-left ml-md-0">
							5 ok, amiért megéri ViddL futárnak lenni
						</h2>
						<ul>
							{texts.map(({ title, description }) => (
								<li key={description} className="mb-4 pb-1">
									<h5>
										<ListBullet />
										{title}
									</h5>
									<p className="text-secondary">{description}</p>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};
